import React from "react"
import PageWrapper from "../components/PageWrapper"


const ImpressumPage = () => {
  return (
    <PageWrapper title="Impressum" >
      <section id="contact" className="p-0">
        <h1 className="my-5">Impressum</h1>
        <p className="mb-4">
          Lucy Gone Wild Futter GbR<br/>
          vertreten durch Kim Schamberger<br/><br/>
          Haldeweg 7 <br/>
          D-89437 Haunsheim<br/><br/>
          Tel.: +49 0175 4150950 <br/>
          Email: kim.schamberger@outlook.de
        </p>
      </section>
    </PageWrapper>
  )
}

export default ImpressumPage
